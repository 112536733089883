<!--
 * @Descripttion : 车辆列表
 * @version      : 
 * @Author       : min
 * @Date         : 2020-12-22 21:43:21
 * @LastEditors  : min
 * @LastEditTime : 2020-12-25 18:13:08
-->
<template>
  <div class="ml_car">
    <div class="ml_search_form">
      <a-form-model layout="inline" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
        <a-form-model-item>
          <a-radio-group button-style="solid" v-model="formInline.status" @change="changeStatus">
            <a-radio-button value="1">
              待售
            </a-radio-button>
            <a-radio-button value="2">
              在售
            </a-radio-button>
            <a-radio-button value="3">
              下架
            </a-radio-button>
            <a-radio-button value="0">
              删除
            </a-radio-button>
            <a-radio-button value="-1">
              禁用
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="formInline.keyword" placeholder="搜索文字" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" :disabled="formInline.user === '' || formInline.password === ''">
            查询
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-table bordered :data-source="data" :columns="columns" :pagination="pagination" rowKey='id' @change="onShowSizeChange">
      <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
        <div>
          <template v-for="(item, index) in record.carImg">
            <img :src="item.carImgUrl" alt="" class="ml_car_img" :key="index">
          </template>
        </div>
        <div style="background-color:#fff;">
          <a-descriptions bordered size="small" :column="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
            <a-descriptions-item label="车牌号">
              {{ record.carPlate }}
            </a-descriptions-item>
            <a-descriptions-item label="使用性质">
              {{ record.carUseNatureName }}
            </a-descriptions-item>
            <a-descriptions-item label="排放标准">
              {{ record.carEmissionStandardName }}
            </a-descriptions-item>
            <a-descriptions-item label="上牌日期">
              {{ record.carRegistrationDate | dateformat("YYYY-MM-DD") }}
            </a-descriptions-item>
            <a-descriptions-item label="出厂日期">
              {{ record.carDischargeDate | dateformat("YYYY-MM-DD") }}
            </a-descriptions-item>
            <a-descriptions-item label="交强险有效期">
              {{ record.carTrafficInsuranceDate | dateformat("YYYY-MM-DD") }}
            </a-descriptions-item>
            <a-descriptions-item label="车辆描述">
              {{ record.carDescription }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </div>
      <template slot="city" slot-scope="record">
        {{ record.carProvinceName }}-{{ record.carCityName }}
      </template>
      <template slot="carRetailPrice" slot-scope="record">
        {{ record.carRetailPrice | priceToShousand }}
      </template>
      <template slot="carTradePrice" slot-scope="record">
        {{ record.carRetailPrice | priceToShousand }}
      </template>
      <template slot="action" slot-scope="record">
        <a-dropdown :trigger="['click']" placement="bottomRight">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            操作
            <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item key="0" @click="updateCarStatus(record)">
              禁用
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>
<script>
import { getCarList, updateCarStatus } from "../utils/api";
let _columns = [
  {
    title: "车辆ID",
    dataIndex: "id",
    align: "center"
  },
  {
    title: "车辆名称",
    dataIndex: "carName",
    align: "center"
  },
  {
    title: "所在城市",
    align: "center",
    scopedSlots: { customRender: "city" }
  },
  {
    title: "零售价格",
    align: "center",
    scopedSlots: { customRender: "carRetailPrice" }
  },
  {
    title: "批售价格",
    align: "center",
    scopedSlots: { customRender: "carTradePrice" }
  }
];
let option = [
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: {
      customRender: "action"
    }
  }
];

export default {
  name: "carManage",
  data() {
    return {
      formInline: {
        status: "1",
        keyword: ""
      },
      data: [],
      columns: null,
      pagination: {
        position: "bottom",
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        current: 1,
        pageSize: 10,
        total: 50,
        showSizeChanger: true
      }
    };
  },
  created() {
    this.columns = _columns.concat(option);
    this.getCarList();
  },
  methods: {
    onShowSizeChange({ current, pageSize }) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getCarList();
    },
    getCarList() {
      const that = this;
      getCarList({
        current: that.pagination.current,
        count: that.pagination.pageSize,
        status: that.formInline.status,
        keyword: that.formInline.keyword
      }).then(res => {
        if (that.pagination.current == 1) {
          that.data = res.data;
        } else {
          that.data = that.data.concat(res.data);
        }
        that.pagination.total = res.count;
      });
    },
    handleSubmit() {
      this.onShowSizeChange({
        current: 1,
        pageSize: 10
      });
    },
    changeStatus() {
      if (this.formInline.status == -1) {
        this.columns = _columns;
      } else {
        this.columns = _columns.concat(option);
      }

      this.onShowSizeChange({
        current: 1,
        pageSize: 10
      });
    },
    updateCarStatus(e) {
      const that = this;
      that.$confirm({
        title: "是否确定禁用该车辆？",
        content: "",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        centered: true,
        onOk() {
          updateCarStatus({
            status: -1,
            id: e.id
          }).then(() => {
            that.$message.success("禁用成功！", 1, () => {
              that.onShowSizeChange({
                current: 1,
                pageSize: 10
              });
            });
          })
        },
        onCancel() { }
      });
    }
  }
}
</script>
<style lang="scss">
.ml_car {
  .ml_search_form {
    padding-bottom: 10px;
  }
  .ml_car_img {
    width: 120px;
    height: 80px;
    margin: 0 10px 10px 0;
    border-radius: 5px;
  }
  .ant-descriptions {
    table {
      border-collapse: collapse;
    }
    .ant-descriptions-row {
      border-collapse: collapse !important;
    }
  }
}
</style>
